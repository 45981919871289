import React from  'react'
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditShareButton,
    RedditIcon,
    EmailShareButton,
    EmailIcon,
    TelegramShareButton,
    TelegramIcon,
} from  'react-share'
const ShareButtons = ({title, url, twitterHandle, tags}) => {

    return(
        <div>
            <FacebookShareButton url={url}  hashtags={tags} >
                <FacebookIcon  size={40} round={true}/>
            </FacebookShareButton>

            <TelegramShareButton url={url} title={title}  hashtags={tags}>
                <TelegramIcon  size={40} round={true} />
            </TelegramShareButton>

            <EmailShareButton url={url} title={title} hashtags={tags}>
                <EmailIcon  size={40} round={true} />
            </EmailShareButton>

            <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                <TwitterIcon  size={40} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton url={url}  title={title} hashtags={tags}>
                <LinkedinIcon  size={40} round={true}/>
            </LinkedinShareButton>

            <RedditShareButton url={url} title={title} >
                <RedditIcon  size={40} round={true} />
            </RedditShareButton>

            <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon  size={40} round={true}/>
            </WhatsappShareButton>
        </div>
    )

}
export default ShareButtons
