import React, { useState } from 'react';
import styled from 'styled-components';
import { FaStar } from 'react-icons/fa';

const QuizContainer = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const QuizHeader = styled.div`
  background-color: #6A70B2;
  color: #fff;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const Question = styled.h2`
  font-size: 1.2rem !important;
  margin-bottom: 15px;
`;

const OptionsList = styled.ul`
  list-style-type: none !important;
  margin-left: 1em !important;
  padding: 0 !important;
  @media (max-width: 768px) {
    margin-left: 0px !important;
  }
`;

const Option = styled.li`
  margin-bottom: 10px !important;
`;

const RadioButton = styled.input`
  margin-right: 5px !important;
  cursor: pointer !important;
`;

const Feedback = styled.p`
  margin-top: 10px;
`;

const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: #49509d;
  border-radius: 5px;
`;

const QuestionIndicator = styled.p`
  padding-top: 16px;
  font-size: 0.9rem;
  color: #888;
  position: absolute;
  bottom: 10px;
  right: 10px;

  @media (max-width: 768px) {
    position: relative;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  background-color: #6A70B2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
  min-width: 152px;

  &:hover {
    background-color: #49509d;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    width: 100%;
  }
`;

const Quiz = ({ questions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [progress, setProgress] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [completed, setCompleted] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowFeedback(true);
    const currentQuestion = questions[currentQuestionIndex];
    if (option === currentQuestion.correctAnswer) {
      setFeedback(currentQuestion.feedback);
      setCorrectAnswers(correctAnswers + 1);
    } else {
      setFeedback('Respuesta incorrecta.');
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption('');
      setShowFeedback(false);
      setFeedback('');
      const newProgress = ((currentQuestionIndex + 1) / questions.length) * 100;
      setProgress(newProgress);
    } else {
      setCompleted(true);
      sendQuizCompletedEvent();
    }
  };

  const sendQuizCompletedEvent = () => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'quiz',
      eventAction: 'complete',
      eventLabel: 'complete_quiz',
    });
  };

  const handleRestartQuiz = () => {
    setCurrentQuestionIndex(0);
    setSelectedOption('');
    setShowFeedback(false);
    setFeedback('');
    setProgress(0);
    setCorrectAnswers(0);
    setCompleted(false);
  };

  const currentQuestion = questions[currentQuestionIndex];
  const questionNumber = currentQuestionIndex + 1;

  const calculateStars = () => {
    const proportionCorrect = correctAnswers / questions.length;
    const numStars = Math.round(proportionCorrect * 5);
    return numStars;
  };

  return (
    <QuizContainer>
      <QuizHeader>Quiz App</QuizHeader>
      {completed ? (
        <>
          <ProgressBarContainer>
            <ProgressOverlay progress={100} />
          </ProgressBarContainer>
          <p>¡Felicidades, has completado el cuestionario!</p>
          <p>Puntuación: {correctAnswers}/{questions.length}</p>
          {[...Array(5)].map((_, index) => (
            <FaStar
              key={index}
              style={{
                width: '30px',
                color: index < (correctAnswers / questions.length) * 5 ? '#ffc107' : '#ccc'
              }}
            />
          ))}
          <br />
          <Button onClick={handleRestartQuiz}>Reiniciar Quiz</Button>
        </>
      ) : (
        <>
          <ProgressBarContainer>
            <ProgressOverlay progress={progress} />
          </ProgressBarContainer>
          <Question>{currentQuestion.question}</Question>
          <OptionsList>
            {currentQuestion.options
              .sort(() => Math.random() - 0.5)
              .map((option, index) => (
                <Option key={index}>
                  <label>
                    <RadioButton
                      type="radio"
                      name="options"
                      value={option}
                      checked={selectedOption === option}
                      onChange={() => handleOptionSelect(option)}
                      disabled={selectedOption !== ''}
                    />
                    {option}
                  </label>
                </Option>
              ))}
          </OptionsList>
          <Feedback>{showFeedback && feedback}</Feedback>
          <Button onClick={handleNextQuestion}>
            {currentQuestionIndex === questions.length - 1 ? 'Finalizar' : 'Siguiente Pregunta'}
          </Button>
          <QuestionIndicator>
            Pregunta {questionNumber}/{questions.length}
          </QuestionIndicator>
        </>
      )}
    </QuizContainer>
  );
};

export default Quiz;
